import { useRef } from 'react';
import cn from 'classnames';
import Svg from 'UI/components/Svg';
import styles from './styles.module.scss';

const AccordionItem = ({
  handleClick,
  title,
  isOpen,
  buttonClassName,
  isOpenClassName,
  isCloseClassName,
  className,
  children,
}) => {
  const ref = useRef(null);

  return (
    <li className={cn(
      styles['accordion-item'],
      className,
      {
        [isCloseClassName]: !isOpen,
        [isOpenClassName]: isOpen,
        [styles.isClose]: !isOpen,
        [styles.isOpen]: isOpen,
      },
    )}
    >
      <button
        type="button"
        className={cn(styles['accordion-button'], buttonClassName)}
        onClick={handleClick}
      >
        <span>{title}</span>
        <Svg
          type="plus"
          className={cn(styles.icon, { [styles.isOpen]: isOpen })}
        />
      </button>
      <div
        className={styles['accordion-collapse']}
        style={isOpen ? { height: ref?.current?.scrollHeight } : { height: '0px' }}
      >
        <div
          ref={ref}
          className={styles['accordion-body']}
        >
          {children}
        </div>
      </div>
    </li>
  );
};

export default AccordionItem;
