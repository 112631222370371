import Svg from 'UI/components/Svg';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import dynamic from 'next/dynamic';
import styles from './styles.module.scss';

const Media = dynamic(() => import('UI/components/Media'));

const TeamCard = (props) => {
  const {
    handlePrevClick,
    handleNextClick,
    image,
    name,
    position,
    description,
    skills,
  } = props;

  return (
    <div className={styles.card}>
      <div className={styles['card-header']}>
        <button
          type="button"
          onClick={handlePrevClick}
          className={styles['action-button']}
        >
          <Svg type="arrowLeft" />
        </button>
        <button
          type="button"
          onClick={handleNextClick}
          className={styles['action-button']}
        >
          <Svg type="arrowRight" />
        </button>
      </div>
      <SwitchTransition>
        <CSSTransition
          key={name}
          timeout={300}
          classNames={{
            enter: styles['fade-enter'],
            enterActive: styles['fade-enter-active'],
            exit: styles['fade-exit'],
            exitActive: styles['fade-exit-active'],
          }}
        >
          <div className={styles['card-body']}>
            {image && (
              <Media
                asset={image}
                className={styles.media}
              />
            )}
            {name && (
              <h4 className={styles.name}>{name}</h4>
            )}
            {position && (
              <p className={styles.position}>{position}</p>
            )}
            {description && (
              <p className={styles.description}>{description}</p>
            )}
            <div className={styles['skills-container']}>
              {(skills || []).map((skill, key) => (
                <p
                  key={key}
                  className={styles.skill}
                >
                  {skill}
                </p>
              ))}
            </div>
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};
export default TeamCard;
