import SectionTitle from 'UI/components/SectionTitle';
import TeamCard from 'UI/components/TeamCard';
import ContentfulParser from 'components/BlogCommon/Article/ContentfulParser';
import AccordionItem from 'UI/components/AccordionItem';
import Svg from 'UI/components/Svg';
import useSectionProps from './utils/useSectionProps';
import styles from './styles.module.scss';

const Team = (props) => {
  const {
    title,
    description,
    accordionList,
    activeAccordion,
    handleAccordionClick,
    teamCardProps,
  } = useSectionProps(props);

  return (
    <section className={styles.section}>
      <SectionTitle
        title={title}
        description={description}
        className={styles.sectionTitle}
      />

      <div className={styles.contentWrapper}>
        <ul className={styles.accordion}>
          {(accordionList || []).map((item, id) => (
            <AccordionItem
              key={id}
              isOpen={id === activeAccordion}
              handleClick={() => handleAccordionClick(id)}
              title={item.title}
            >
              <ContentfulParser
                document={item.text}
                linkIcon={<Svg type="arrowNarrowUp" />}
              />
            </AccordionItem>
          ))}
        </ul>
        <TeamCard {...teamCardProps} />
      </div>
    </section>
  );
};

export default Team;
