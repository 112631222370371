import {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { getDocumentFields } from 'utils/helper';

export default ({
  section,
  data,
  type,
}) => {
  const [activeAccordion, setAccordion] = useState(0);
  const [activeCard, setCard] = useState(0);

  const {
    title,
    description,
    contentModules,
    view,
  } = getDocumentFields(
    data || section,
    [
      'title',
      'description',
      'contentModules',
      'view',
    ],
  );

  const handleNextClick = useCallback((length) => {
    setCard((prevIndex) => (prevIndex + 1) % length);
  }, [setCard]);

  const handlePrevClick = useCallback((length) => {
    setCard((prevIndex) => (prevIndex - 1 + length) % length);
  }, [setCard]);

  const handleAccordionClick = useCallback((id) => {
    setAccordion(id);
    setCard(0);
  }, []);

  const accordionList = useMemo(() => (contentModules || [])
    .map((item, id) => getDocumentFields(item, [
      'title',
      'text',
    ])), [contentModules]);

  const teamCardProps = useMemo(() => {
    const { contentModules: modules } = getDocumentFields(
      contentModules[activeAccordion],
      ['contentModules'],
    );

    const {
      fullName,
      avatar,
      bio,
      position,
      skills,
    } = getDocumentFields(
      modules[activeCard],
      [
        'fullName',
        'avatar',
        'bio',
        'position',
        'skills',
      ],
    );

    return {
      image: avatar,
      name: fullName,
      position,
      description: bio,
      skills,
      handleNextClick: () => handleNextClick(modules?.length),
      handlePrevClick: () => handlePrevClick(modules?.length),
    };
  }, [
    activeAccordion,
    contentModules,
    activeCard,
    handleNextClick,
    handlePrevClick,
  ]);

  return {
    type,
    view,
    title,
    description,
    teamCardProps,
    accordionList,
    activeAccordion,
    handleAccordionClick,
  };
};
